const CHATAPP_API_URL = process.env.REACT_APP_CHATAPP_API_URL as
  | string
  | "https://api.chatbot.robin-krueger.de";

type Message = {
  message: string;
  sender: "human" | "ai" | "system";
  sources?: string[];
};

type MessageHistory = Message[];

const getLangchainResponse = async (
  messageHistory: MessageHistory,
  temperature: number
) => {
  console.log("api url", CHATAPP_API_URL);
  try {
    const apiResponse = await fetch(CHATAPP_API_URL, {
      method: "POST", // no strict cors policy
      headers: {
        "Content-Type": "application/json",
        // allow cors
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
      },
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        message_history: messageHistory,
        temperature: 0.0,
      }),
    });
    const data = await apiResponse.json();
    return { response: data.response, sources: data.sources };
  } catch (error) {
    console.log(error);
  }
};

export default getLangchainResponse;
