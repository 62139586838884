import React from "react";
import ChatMessage from "../molecules/ChatMessage";
import InfoProfile from "../../assets/profile.jpeg";
import GenericProfile from "../../assets/GenericProfile.jpeg";

const Chat = () => {
  return (
    <>
      <div className="w-screen h-screen bg-slate-100 relative pt-10">
        <div className="px-5 lg:w-2/3 mx-auto">
          <ChatMessage
            position="left"
            sender="Master Chief"
            Profile={InfoProfile}
          >
            Hallo, ich bin der Chatassitent für Inforamatik Elite. Wie kann ich
            dir helfen?
          </ChatMessage>
          <ChatMessage position="right" sender="Du" Profile={GenericProfile}>
            Ich bin 2 Mal duch die Informatikklausur gefallen. Wie kann ich
            einer Exmatrikulation entgehen?
          </ChatMessage>
          <ChatMessage
            position="left"
            sender="Master Chief"
            Profile={InfoProfile}
          >
            Das ist natürlcih sehr schade. Aber keine Sorge, wir haben eine
            Lösung. Du kannst einen Antrag auf eine dritte Wiederholung stellen.
            Diesen findest du{" "}
            <a
              href="https://www.hs-pforzheim.de/fileadmin/user_upload/uploads_redakteur/Studentische_Abteilung/Dokumente/Studienorganisation/Pruefungen/Studienverlaufsplanung/Antrag_Drittversuch_Studienzeitverlaengerung.pdf"
              className="link"
            >
              hier
            </a>
            . Bitte fülle diesen aus und reiche ihn beim Prüfungsamt ein. Um die
            Informatikklausur zu bestehen, solltest du dich Bestmöglich
            vorbereiten. Dazu solltest Du die Vorlesungen und das Tutorium
            besuchen und die dort gestellten Aufgaben selbstständig und
            gewissenhaft bearbeiten. Zusätzlich solltest Du selbstständig
            weitere Übungsaufgaben wie beispielsweiise JSHero lösen.
          </ChatMessage>
          <ChatMessage position="right" sender="Du" Profile={GenericProfile}>
            Ok das werde ich veruchen. Vielen Dank für die Hilfe!
          </ChatMessage>
          <ChatMessage
            position="left"
            sender="Master Chief"
            Profile={InfoProfile}
          >
            Ich helfe immer gerne! Falls du noch weitere Fragen hast, kannst du
            dich gerne an mich wenden. Viel Erfolg bei der Klausur!
          </ChatMessage>
        </div>
      </div>
    </>
  );
};

export default Chat;
