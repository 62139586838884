import React, { useEffect } from "react";
import ChatMessage from "../molecules/ChatMessage";
import StudiendenberatungProfile from "../../assets/HSProfile.jpg";
import GenericProfile from "../../assets/GenericProfile.jpeg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import getLangchainResponse from "../../services/getLangchainResponse";

type Message = {
  sender: "system" | "human" | "ai";
  message: string;
  sources?: any[];
};

const Chat = () => {
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [input, setInput] = React.useState<string>("");
  const [temperature, setTemperature] = React.useState<number>(0);
  const [waitingForResponse, setWaitingForResponse] =
    React.useState<boolean>(false);

  const messageEndRef = React.useRef<HTMLDivElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  // useEffect(() => {
  //   document.title = "Studierendenberatung Chatbot";
  // }, []);

  const handleSendMessage = () => {
    if (input && !waitingForResponse) {
      setMessages([
        ...messages,
        { sender: "human", message: input },
        { sender: "system", message: "..." },
      ]);
      setWaitingForResponse(true);
      setInput("");
      getLangchainResponse(
        [
          ...messages,
          {
            message: input,
            sender: "human",
          },
        ],
        temperature
      ).then(
        (apiResponse: { response: string; sources: any[] } | undefined) => {
          if (!apiResponse) return;
          setMessages([
            ...messages,
            { sender: "human", message: input },
            {
              sender: "ai",
              message: apiResponse.response,
              sources: apiResponse.sources,
            },
          ]);
          setWaitingForResponse(false);
          console.log("messages: ", messages);
        }
      );
    }
  };

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  console.log("render");
  return (
    <>
      <div className="w-screen h-screen bg-slate-100 ">
        <div className="w-full h-[90%] relative pt-10 overflow-scroll hide-scrollbar">
          <div className=" lg:w-2/3 mx-5 lg:mx-auto overflow-hidden">
            {messages.map((message, index) => {
              console.log("message: ", message);
              return (
                <ChatMessage
                  key={index}
                  position={message.sender === "human" ? "right" : "left"}
                  sender={
                    message.sender === "human"
                      ? "Sie"
                      : "Chatbot der Studierendenberatung"
                  }
                  Profile={
                    message.sender === "human"
                      ? GenericProfile
                      : StudiendenberatungProfile
                  }
                >
                  {message.message}
                  {message.sources && message.sources.length > 0 && (
                    <div className="mt-2">
                      <p className="text-xs text-gray-500">
                        Quellen: <br />
                        {message.sources.map((source, index) => {
                          let page: string | undefined = undefined;
                          if (source.includes("?Seite=")) {
                            [source, page] = source.split("?Seite=");
                          }
                          return (
                            <a
                              key={index}
                              href={source}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="underline sm:hidden">
                                {source.length > 30
                                  ? source.substring(0, 35) + "..."
                                  : source}
                              </div>
                              <div className="underline hidden sm:block">
                                {source}
                              </div>
                              {page && (
                                <span className="text-gray-400">
                                  (Seite {page})
                                </span>
                              )}
                            </a>
                          );
                        })}
                      </p>
                    </div>
                  )}
                </ChatMessage>
              );
            })}
            <div ref={messageEndRef} />
          </div>
          <div className="fixed bottom-0 left-0 right-0 lg:w-3/4 mx-5 lg:mx-auto flex justify-center">
            <input
              type="text"
              placeholder="Type here"
              className="input input-bordered primary w-full max-w-2xl mx-5 mb-5"
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  buttonRef.current?.click();
                  e.preventDefault();
                }
              }}
              value={input}
            />
            <div className="w-min">
              <button
                ref={buttonRef}
                className="btn btn-circle btn-primary"
                onClick={handleSendMessage}
              >
                <FontAwesomeIcon icon={faPlay} className="text-white" />
              </button>
            </div>
            <button
              className="btn hidden sm:block ml-5"
              onClick={() => {
                const modal = document.getElementById(
                  "settings_modal"
                ) as HTMLDialogElement;
                if (modal !== null) {
                  modal.showModal();
                }
              }}
            >
              Einstellungen
            </button>
            <dialog id="settings_modal" className="modal">
              <div className="modal-box">
                <h3 className="font-bold text-xl">Einstellungen</h3>
                <span className="text-lg my-3">
                  Wenn die Seite neu geladen wurde, werden die Einstellungen
                  zurückgesetzt.
                </span>
                <div className="mt-5">
                  <label htmlFor="temp">Temperatur ({temperature})</label>
                  <div>
                    <input
                      type="range"
                      min={0}
                      max="2"
                      value={temperature}
                      className="range range-xs"
                      step="0.1"
                      onChange={(e) => {
                        setTemperature(parseFloat(e.target.value));
                      }}
                    />
                    <div className="w-full flex justify-between text-xs px-2">
                      <span className="font-bold text-lg">0</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span className="font-bold text-lg">1</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span>|</span>
                      <span className="font-bold text-lg">2</span>
                    </div>
                  </div>
                </div>
                <div className="modal-action">
                  <form method="dialog">
                    <button className="btn">Close</button>
                  </form>
                </div>
              </div>
            </dialog>
          </div>
          <br />
          <button
            className="btn sm:hidden absolute bottom-5 right-5"
            onClick={() => {
              const modal = document.getElementById(
                "settings_modal"
              ) as HTMLDialogElement;
              if (modal !== null) {
                modal.showModal();
              }
            }}
          >
            Einstellungen
          </button>
        </div>
      </div>
    </>
  );
};

export default Chat;
