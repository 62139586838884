import React from "react";

type Props = {
  message?: string;
  position?: "left" | "right";
  status?: "sent" | "delivered" | "seen";
  time?: string;
  sender: string;
  children?: React.ReactNode;
  Profile?: any;
};

const ChatMessage = (props: Props) => {
  const { position, status, time, children, sender, Profile } = props;
  return (
    <>
      <div
        className={
          position === "right" ? "chat chat-end mt-5" : "chat chat-start mt-5"
        }
      >
        <div className="chat-image avatar rounded-full shadow-md w-min">
          <div className="w-10 rounded-full">
            <img alt="Tailwind CSS chat bubble component" src={Profile} />
          </div>
        </div>
        <div className="chat-header font-bold">
          {sender}
          {time ? <time className="text-xs opacity-50">{time}</time> : null}
        </div>
        <div className="chat-bubble shadow-md overflow-hidden">{children}</div>
        {status ? <div className="chat-footer opacity-50">{status}</div> : null}
      </div>
    </>
  );
};

export default ChatMessage;
